@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $color-black url('../img/bg/bg.png') no-repeat center top;
	background-size: cover;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	line-height: 1;
	text-align: center;
	text-transform: uppercase;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

header {
	padding: rem(40) 0;

	.socials {
		list-style: none;
		margin: 0 0 rem(25) 0;
		padding: 0;

		> li {
			display: inline-block;
			font-size: rem(20);
			vertical-align: middle;

			+ li {
				margin-left: rem(10);
			}

			> a {
				color: $color-blue;

				@include hover-focus {
					color: $color-white;
				}
			}
		}
	}

	h1 {
		margin: 0 auto;
		max-width: rem(183);

		> img {
			margin: 0 auto;
		}
	}
}

main {
	.container {
		max-width: 700px;
	}

	#music {
		img {
			box-shadow: 10px 11px 97.97px 3.03px rgba(34, 14, 102, 0.41);
			margin: 0 auto rem(40) auto;
		}

		a {
			border: 2px solid $color-white;
			color: $color-salmon;
			display: inline-block;
			font-size: rem(16);
			font-weight: 700;
			letter-spacing: letter-spacing(200);
			padding: rem(20) rem(30);
			text-indent: text-indent(200);
			vertical-align: middle;

			@include hover-focus {
				background: $color-white;
			}
		}
	}

	#newsletter {
		margin-top: rem(100);

		h2 {
			font-size: rem(25);
			font-weight: 700;
			margin: 0 0 rem(50) 0;
		}

		form {
			input {
				background: none;
				border: 0;
				border-bottom: 2px solid $color-salmon;
				border-radius: 0;
				color: $color-white;
				display: block;
				font-size: rem(14);
				font-weight: 400;
				letter-spacing: letter-spacing(300);
				line-height: 1;
				margin: 0 0 rem(40) 0;
				padding: 0 0 rem(8) 0;
				text-align: center;
				text-indent: text-indent(300);
				width: 100%;

				&:focus {
					border-color: $color-white;
					outline: 0;
				}

				@include placeholder {
					color: $color-white;
					text-transform: uppercase;
				}
			}

			button {
				background: $color-salmon;
				border: 0;
				border-radius: 0;
				color: $color-black;
				display: inline-block;
				font-size: rem(16);
				font-weight: 700;
				letter-spacing: letter-spacing(300);
				padding: rem(12) rem(30);
				text-indent: text-indent(300);
				text-transform: uppercase;
				vertical-align: middle;

				@include hover-focus {
					background: $color-black;
					color: $color-salmon;
				}
			}

			@include phone-up {
				.inputs {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: space-between;

					> input {
						flex: 0 0 calc(50% - #{rem(7.5)});
					}
				}
			}
		}
	}
}

footer {
	padding: rem(112) 0 rem(75) 0;

	.copyright {
		font-size: rem(12);
		letter-spacing: letter-spacing(200);
		line-height: line-height(24, 12);
		text-indent: text-indent(200);

		p {
			margin: 0;

			a {
				@include hover-focus {
					text-decoration: underline;
				}
			}
		}
	}
}
